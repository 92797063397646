import { Env } from '@region-lib/env';
import { fetch } from '../utils/request';

const { API_HOST } = Env;

const URL = {
  dashboardData: `${API_HOST}/dashboard/get-data`,
  signupEmail: `${API_HOST}/index/public/v1/waitlist/user/save`,
  referInfo: `${API_HOST}/api/affiliate_api/public/getNameByCode`,
  poolList: `${API_HOST}/trade/public/v1/market/risk-pool-list`,
  bindInvideCode: `${API_HOST}/user/public/v1/client/binding`
};

export const getDashboardData = () => {
  return fetch({
    url: URL.dashboardData,
    method: 'GET'
  });
};

export const postSignUp = (data) => {
  return fetch({
    url: URL.signupEmail,
    method: 'POST',
    data
  });
};

export const getReferInfo = (inviteCode: string) => {
  return fetch({
    url: URL.referInfo,
    method: 'GET',
    params: { invite_code: inviteCode }
  });
};

export const getPoolList = () => {
  return fetch({
    url: URL.poolList,
    method: 'GET',
    params: { symbol: 'BTCUSDT' }
  });
};

export const bindInviteCode = (inviteCode: string) => {
  return fetch({
    url: URL.bindInvideCode,
    method: 'Post',
    data: { invite_code: inviteCode }
  });
};
